html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: "#000";
}

a {
  color: "#fff";
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Allura";
  src: url("../Assets/fonts/Allura/Allura-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
